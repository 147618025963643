/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import { ContentRoute } from '../../../../_metronic/layout';
import { ErrorPage5 } from '../../ErrorsExamples/ErrorPage5';
import NewPassword from './NewPassword';
import '../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss';

export function AuthPage() {
  return (
    <>
      <div className="d-flex flex-column flex-root">
        <div
          className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white"
          id="kt_login"
        >
          <div className="flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden">
            <div className="d-flex flex-column-fluid flex-center">
              <img
                alt="Logo"
                className="max-h-100px max-w-300px"
                src={toAbsoluteUrl('/media/Logodo.png')}
              />
            </div>

            <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
              <Switch>
                <ContentRoute path="/auth/new-password" component={NewPassword} />
                <ContentRoute path="/" component={ErrorPage5} />
                <Redirect from="/auth" exact={true} to="/auth/new-password" />
                <Redirect to="/" />
              </Switch>
            </div>

            <div className="d-none flex-column-auto d-lg-flex justify-content-between mt-10">
              <div className="opacity-70 font-weight-bold	text-black">&copy; 2021 Odopass</div>
            </div>

            <div className="d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5">
              <div className="text-dark-50 font-weight-bold order-2 order-sm-1 my-2">
                &copy; 2021 Odopass
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
