import axios from "axios";

let BASE_URL = "http://127.0.0.1:8090/";

if (process.env.REACT_APP_ENV === "staging") {
  BASE_URL = "https://staging.odopass.tech/api/v4/";
} else if (process.env.REACT_APP_ENV === "developement") {
  BASE_URL = "http://127.0.0.1:8090/";
} else if (process.env.REACT_APP_ENV === "production") {
  BASE_URL = "https://pro.odopass.tech/api/v4/";
}

export const LOGIN_URL = BASE_URL + "auth/login";
export const REGISTER_URL = BASE_URL + "auth/register";
export const REQUEST_PASSWORD_URL = BASE_URL + "auth/new-password";
export const NEW_PASSWORD_URL = BASE_URL + "auth/new-password";
export const DELETE_ACCOUNT_URL = BASE_URL + "delete-account";

export const ME_URL = BASE_URL + "profile/me";

export function deleteAccount(email, password, reason) {
  return axios.post(DELETE_ACCOUNT_URL, {
    email: email,
    password: password,
    reason: reason,
  });
}

export function login(email, password) {
  console.log(email, password);
  return axios.post(LOGIN_URL, { email, password, fcmToken: "webapp" });
}

export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, {
    email,
    fullname,
    username,
    password,
    fcmToken: "webapp",
  });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function newPassword({ email, password, resetGuid }) {
  return axios.post(NEW_PASSWORD_URL, { email, password, resetGuid });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}
