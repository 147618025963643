import React, { useState } from "react";
import { useFormik } from "formik";
import { useLocation } from "react-router-dom";
import * as Yup from "yup";
import { deleteAccount } from "../_redux/authCrud";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";

const initialValues = {
  email: "",
};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function DeleteAccount(props) {
  let query = useQuery();
  const [passwordShown, setPasswordShown] = useState(false);

  const [isRequested, setIsRequested] = useState(false);

  const togglePassword = () => {
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setPasswordShown(!passwordShown);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const DeleteAccountSchema = Yup.object().shape({
    email: Yup.string()
      .email("Email invalide")
      .required("Email requis"),
    password: Yup.string().required("Mot de passe requis"),
    reason: Yup.string().required(
      "veuiller indiquer le motif de suppression du compte"
    ),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: DeleteAccountSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      deleteAccount({
        email: values.email,
        password: values.password,
        reason: values.reason,
      })
        .then((response) => {
          console.log(response);
          setIsRequested(true);
        })
        .catch((error) => {
          console.log(error.response);
          if (error.response) {
            const returned_response = error.response.data;
            console.log(returned_response);
            switch (error.response.data.error) {
              case "NO_EMAIL_MATCHING":
                setStatus(
                  "Email incorrect: aucun compte Odopass ne correspond a cet email"
                );
                break;
              case "BAD_PASSWORD":
                setStatus(
                  `Mot de passe incorrect. En cas d'oubli, reinitialisez votre mot de passe depuis l'application Odopass`
                );
                break;
              default:
                setStatus("Ce compte n'existe pas");
                break;
            }
            console.log(error.response.data);
          }

          setIsRequested(false);
          setSubmitting(false);
        });
    },
  });

  return (
    <>
      {isRequested && (
        <div className="login-form login-forgot" style={{ display: "block" }}>
          <div className="text-center mb-10 mb-lg-20">
            <h3 className="font-size-h1">Supprimer votre compte</h3>
            <div className="text-muted font-weight-bold">
              {query.get("email")}
            </div>
          </div>
          <div className="mb-10 alert alert-custom alert-light-success alert-dismissible">
            <div className="alert-text font-weight-bold">
              Compte supprimé avec succès
            </div>
          </div>
        </div>
      )}
      {!isRequested && (
        <div className="login-form login-forgot" style={{ display: "block" }}>
          <div className="text-center mb-10 mb-lg-20">
            <h3 className="font-size-h1">Supprimer votre compte</h3>
            <div className="text-muted font-weight-bold">
              {query.get("email")}
            </div>
          </div>
          <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
          >
            {formik.status && (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">
                  {formik.status}
                </div>
              </div>
            )}
            <div className="form-group fv-plugins-icon-container">
              <input
                type="email"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "email"
                )}`}
                placeholder="Adresse email"
                name="email"
                id="InputInfo"
                {...formik.getFieldProps("email")}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                  <div className="alert-text font-weight-bold">
                    {formik.errors.email}
                  </div>
                </div>
              ) : null}
            </div>
            <div className="form-group fv-plugins-icon-container">
              <div className=" d-flex align-items-center">
                <input
                  type={passwordShown ? "text" : "password"}
                  className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                    "password"
                  )}`}
                  placeholder="Mot de passe"
                  name="password"
                  {...formik.getFieldProps("password")}
                />
                <button
                  id="kt_login_forgot_submit"
                  type="submit"
                  className="btn blue-odopass font-weight-bold px-9 py-4  ml-4"
                  onClick={togglePassword}
                >
                  {passwordShown ? (
                    <VisibilityOutlinedIcon />
                  ) : (
                    <VisibilityOffOutlinedIcon />
                  )}
                </button>
              </div>
              {formik.touched.password && formik.errors.password ? (
                <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                  <div className="alert-text font-weight-bold">
                    {formik.errors.password}
                  </div>
                </div>
              ) : null}
            </div>

            <div className="form-group fv-plugins-icon-container">
              <textarea
                type="reason"
                className={`form-control form-control-solid h-auto py-5 px-6 overflow-scroll ${getInputClasses(
                  "reason"
                )}`}
                placeholder="Motif de suppression"
                name="reason"
                {...formik.getFieldProps("reason")}
              />
              {formik.touched.reason && formik.errors.reason ? (
                <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                  <div className="alert-text font-weight-bold">
                    {formik.errors.reason}
                  </div>
                </div>
              ) : null}
            </div>
            <div className="form-group d-flex flex-wrap flex-center">
              <button
                id="kt_login_forgot_submit"
                type="submit"
                className="btn blue-odopass font-weight-bold px-9 py-4 my-3 mx-4"
                disabled={formik.isSubmitting}
              >
                Confirmer
              </button>
            </div>
          </form>
        </div>
      )}
    </>
  );
}

export default DeleteAccount;
