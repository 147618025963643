import React, { useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import * as Yup from "yup";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { newPassword } from "../_redux/authCrud";

const initialValues = {
  email: "",
};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function NewPassword(props) {
  let query = useQuery();

  const [isRequested, setIsRequested] = useState(false);
  const NewPasswordSchema = Yup.object().shape({
    password: Yup.string()
      .matches(
        /^((?=.*[a-z])(?=.*[A-Z])(?=.*\d)).{8,30}$/,
        "Saisissez un mot de passe avec 8 caractères minimum, au moins une minuscule, majuscule et un chiffre"
      )
      .required(
        "Mot de passe requis"
        /*
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
        */
      ),
    confirm_password: Yup.string()
      .oneOf(
        [Yup.ref("password"), null],
        "Les mots de passe ne correspondent pas"
      )
      .required(
        "Confirmation requise"
        /*
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
        */
      ),
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: NewPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      newPassword({
        email: query.get("email"),
        password: values.password,
        resetGuid: query.get("token"),
      })
        .then((response) => {
          console.log(response);
          setIsRequested(true);
        })
        .catch((error) => {
          console.log(error.response);
          if (error.response) {
            switch (error.response.data.error) {
              case "EXPIRED_GUID":
                setStatus("Une erreur est survenue / lien expiré");
                break;
              case "SAME_PASSWORD":
                setStatus(
                  "Vous ne pouvez pas rentrer votre ancien mot de passe"
                );
                break;
              default:
                setStatus("Une erreur est survenue");
                break;
            }
            console.log(error.response.data);
          }

          setIsRequested(false);
          setSubmitting(false);
        });
    },
  });

  return (
    <>
      {isRequested && (
        <div className="login-form login-forgot" style={{ display: "block" }}>
          <div className="text-center mb-10 mb-lg-20">
            <h3 className="font-size-h1">Nouveau mot de passe</h3>
            <div className="text-muted font-weight-bold">
              {query.get("email")}
            </div>
          </div>
          <div className="mb-10 alert alert-custom alert-light-success alert-dismissible">
            <div className="alert-text font-weight-bold">
              Mot de passse modifié avec succès, Vous pouvez l'utiliser sur
              l'application odopass
            </div>
          </div>
        </div>
      )}
      {!isRequested && (
        <div className="login-form login-forgot" style={{ display: "block" }}>
          <div className="text-center mb-10 mb-lg-20">
            <h3 className="font-size-h1">Nouveau mot de passe</h3>
            <div className="text-muted font-weight-bold">
              {query.get("email")}
            </div>
          </div>
          <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
          >
            {formik.status && (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">
                  {formik.status}
                </div>
              </div>
            )}
            <div className="form-group fv-plugins-icon-container">
              <input
                type="password"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "email"
                )}`}
                placeholder="Mot de passe"
                name="password"
                {...formik.getFieldProps("password")}
              />
              {formik.touched.password && formik.errors.password ? (
                <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                  <div className="alert-text font-weight-bold">
                    {formik.errors.password}
                  </div>
                </div>
              ) : null}
            </div>
            <div className="form-group fv-plugins-icon-container">
              <input
                type="password"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "email"
                )}`}
                placeholder="Confirmer mot de passe"
                name="confirm_password"
                {...formik.getFieldProps("confirm_password")}
              />
              {formik.touched.confirm_password &&
              formik.errors.confirm_password ? (
                <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                  <div className="alert-text font-weight-bold">
                    {formik.errors.confirm_password}
                  </div>
                </div>
              ) : null}
            </div>
            <div className="form-group d-flex flex-wrap flex-center">
              <button
                id="kt_login_forgot_submit"
                type="submit"
                className="btn blue-odopass font-weight-bold px-9 py-4 my-3 mx-4"
                disabled={formik.isSubmitting}
              >
                Mettre à jour
              </button>
            </div>
          </form>
        </div>
      )}
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(NewPassword));
